import { translate } from '@lang/index';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
	faBook,
	faBarsProgress,
	faChair,
	faPeopleGroup,
	faUser,
	faBookOpenReader,
	faBuildingNgo,
	faClipboardCheck,
	faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import C from '@root/constants';
// API
import api from 'api';
// Hooks
import { useOrganisation, usePermissions } from '@hooks/index';
// Utils
import { dateHelpers } from '@utils/index';
// Components
import PageWrapper from '@components/layout/PageWrapper';
import StatGrid from '@components/blocks/StatGrid';
import TablePreviews from '@components/blocks/TablePreviews';
import TableCard from '@components/cards/TableCard';
import StatCard from '@components/cards/StatCard';
import OrgPlanCount from '@components/partials/OrgPlanCount';
import StartingPoints from '@components/blocks/StartingPoints';
import PageHeading from '@components/blocks/PageHeading';
import UpsellButton from '@components/partials/UpsellButton';
// Lists
import CertificatesList from '@components/lists/CertificatesList';
import SearchAgainList from '@components/lists/SearchAgainList';
import NominatedCoursesList from '@components/lists/NominatedCoursesList';
import BookmarksList from '@components/lists/BookmarksList';
import CoursesList from '@components/lists/CoursesList';
import LastViewedList from '@components/lists/LastViewedList';
// Rows
import LoginLogRow from '@components/rows/LoginLogRow';
import ProgressRow from '@components/rows/ProgressRow';
import OrgCourseRow from '@components/rows/OrgCourseRow';
import OrgVideoViewRow from '@components/rows/OrgVideoViewRow';
import HeadingTabs from '@components/partials/HeadingTabs';

interface StandardViewProps {
	user: CurrentUserRes;
}

const StandardView: React.FC<StandardViewProps> = ({ user }) => {
	// --------------------------------------
	// State / Hooks
	const { convertDate, currentDate } = dateHelpers;
	const { organisation } = useOrganisation();
	const [activeOrgId, setActiveOrgId] = useState<number | null>(null);
	const [activeMode, setActiveMode] = useState('manage_organisation');

	const { viewCourses, hasOrgPlan, manageOrganisationWithPlan } =
		usePermissions();

	// --------------------------------------
	// Effects

	useEffect(() => {
		if (organisation) {
			if (organisation.id !== activeOrgId)
				setActiveOrgId(organisation.id);
		}
	}, [organisation, activeOrgId]);

	useEffect(() => {
		if (viewCourses && manageOrganisationWithPlan) {
			setActiveMode('manage_organisation');
		} else if (viewCourses && !manageOrganisationWithPlan) {
			setActiveMode('view_courses');
		} else if (!viewCourses && manageOrganisationWithPlan) {
			setActiveMode('manage_organisation');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOrgId]);

	// -------------------------------------------------
	// Memos
	const viewCoursesActive = useMemo(() => {
		return activeMode === 'view_courses';
	}, [activeMode]);

	const manageOrganisationActive = useMemo(() => {
		return activeMode === 'manage_organisation';
	}, [activeMode]);

	const showHeadingTabs = useMemo(() => {
		return viewCourses && manageOrganisationWithPlan;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organisation]);

	const startingPointCards = useMemo(() => {
		if (activeMode === 'manage_organisation') {
			return [
				{
					title: 'Manage teams',
					description: 'Create and manage teams',
					icon: faPeopleGroup,
					href: `/${organisation?.slug}/teams`,
					permission: organisation?.plan_id !== null,
				},
				{
					title: 'Manage users',
					description: 'Create and manage users',
					icon: faUser,
					href: `/${organisation?.slug}/users`,
					permission: organisation?.plan_id !== null,
				},
				{
					title: 'Manage organisation',
					description: 'Manage your organisation',
					icon: faBuildingNgo,
					href: `/${organisation?.slug}/manage`,
					permission: organisation?.plan_id !== null,
				},
				{
					title: 'Account',
					description: 'Manage my account',
					icon: faUser,
					href: '/account',
				},
			];
		}
		if (activeMode === 'view_courses') {
			return [
				{
					title: 'Courses',
					description: 'View my courses',
					icon: faBook,
					href: `/${organisation?.slug}/courses`,
				},
				{
					title: 'Video library',
					description: 'View the video library',
					icon: faBookOpenReader,
					href: `/${organisation?.slug}/video-library`,
				},
				{
					title: 'Progress',
					description: 'View my progress',
					icon: faBarsProgress,
					href: `/${organisation?.slug}/progress`,
				},
				{
					title: 'Account',
					description: 'Manage my account',
					icon: faUser,
					href: '/account',
				},
			];
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeMode, organisation]);

	// -------------------------------------------------
	// Queries / Mutations
	const organisationRes = useQuery(
		['organisations.current.get', organisation],
		() => {
			return api.organisations.current.get({
				id: organisation?.id as number,
				include: {
					media: true,
					mediaCount: true,
					plan: true,
					planCount: true,
					meta: true,
					usersCount: true,
					seatedUsersCount: true,
				},
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisationActive,
		}
	);
	const categories = useQuery(
		['organisations.categories.getAll', organisation?.id],
		() => {
			return api.organisations.categories.getAll({
				organisationId: organisation?.id as number,
				include: {
					meta: true,
					metaCount: false,
				},
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);
	const courseProgress = useQuery(
		['organisations.courses.progress.getMultiple', organisation?.id],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: false,
					user: false,
				},
				perPage: -1,
			});
		},
		{
			enabled: !!organisation?.id && viewCoursesActive,
		}
	);
	const coursesCompleted = useQuery(
		['organisations.courses.progress.getMultiple.completed'],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: true,
					user: true,
				},
				filters: [
					{
						key: 'completed',
						value: 1,
					},
				],
				perPage: 1,
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);
	const coursesInProgress = useQuery(
		['organisations.courses.progress.getMultiple.inProgress'],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: true,
					user: true,
				},
				filters: [
					{
						key: 'completed',
						value: 0,
					},
				],
				perPage: 1,
			});
		},
		{
			enabled: !!organisation?.id,
		}
	);
	const usersActiveToday = useQuery(
		['sa.courseProgress.getMultiple.today'],
		() => {
			return api.organisations.courses.progress.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					course: true,
					user: true,
				},
				filters: [
					{
						key: 'updated_at_from',
						value: convertDate(currentDate),
					},
				],
				sort: {
					created_at: 'desc',
				},
				perPage: 5,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisationActive,
		}
	);
	const logs = useQuery(
		['organisations.logs.getMultiple'],
		() => {
			return api.organisations.logs.getMultiple({
				organisationId: organisation?.id || 0,
				include: {
					type: true,
					user: true,
				},
				sort: {
					created_at: 'desc',
				},
				perPage: 5,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisationActive,
		}
	);
	const mostTakenCourses = useQuery(
		['organisations.courses.getMultiple.progressCount'],
		() => {
			return api.organisations.courses.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					items: false,
					itemsModel: false,
					itemsModelType: false,
					media: false,
					type: false,
					teamsCount: false,
					usersCount: false,
				},
				sort: {
					progress_count: 'desc',
				},
				perPage: 5,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisationActive,
		}
	);
	const videoViewCount = useQuery(
		['organisations.videos.getMultiple.viewsCount'],
		() => {
			return api.organisations.videos.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					cues: false,
					media: false,
				},
				sort: {
					views_count: 'desc',
				},
				perPage: 5,
			});
		},
		{
			enabled: !!organisation?.id && manageOrganisationActive,
		}
	);
	const videoViews = useQuery(
		['organisations.videos.views.getMultiple'],
		() => {
			return api.organisations.videos.views.getMultiple({
				organisationId: organisation?.id as number,
				include: {
					videoMedia: true,
					course: true,
					courseCount: false,
					video: true,
					videoCount: false,
				},
				sort: {
					viewed_at: 'desc',
				},
				perPage: 4,
			});
		},
		{
			enabled: !!organisation?.id && viewCoursesActive,
		}
	);

	// -------------------------------------------------
	// Functions
	const navigateLocation = (
		path: string,
		filter: {
			key: string;
			value: string;
		}
	) => {
		const filterString = `filter[${filter.key}]=${filter.value}`;
		return `/${organisation?.slug}${path}?${filterString}`;
	};

	// -------------------------------------------------
	// Memos
	const courseDataIsLoading = useMemo(() => {
		return categories.isLoading || courseProgress.isLoading;
	}, [categories.isLoading, courseProgress.isLoading]);

	const courseDataIsError = useMemo(() => {
		return categories.isError || courseProgress.isError;
	}, [categories.isError, courseProgress.isError]);

	const loginRows = useMemo(() => {
		return logs.data?.data.data.filter((log) => log.type.key === 'login');
	}, [logs.data?.data.data]);

	const kappaHeadingBodyRaw = useMemo(() => {
		if(activeMode !== 'view_courses') return;
		if(organisation?.slug !== 'kappa') return;

		return (
			<div className="mt-2 max-w-4xl">
				<p>{translate('kappa_dashboard_body')}</p>
				<a 
					className="mt-2.5 block text-brandPrimary hover:text-brandPrimaryHover hover:underline" 
					href={C.KAPPA.MAILCHIMP_FORM_HREF}
					target='_blank'
					rel="noopener noreferrer"
				>
						{translate('kappa_dashboard_body_link')}
				</a>
			</div>
		)
	}, [activeMode, organisation])

	// -------------------------------------------------
	// Render
	return (
		<>
			<PageHeading
				title={translate('dashboard')}
				bodyRaw={kappaHeadingBodyRaw}
				actions={
					<UpsellButton pulse={true} />
				}
			>
				{showHeadingTabs && (
					<HeadingTabs
						activeKey={activeMode}
						tabs={[
							{
								name: 'Admin',
								key: 'manage_organisation',
								onClick: () =>
									setActiveMode('manage_organisation'),
							},
							{
								name: 'Standard',
								key: 'view_courses',
								onClick: () => setActiveMode('view_courses'),
							},
						]}
					/>
				)}
			</PageHeading>
			<PageWrapper>
				{/* Starting Points */}
				<StartingPoints cards={startingPointCards || []} />

				{/* Stats grid */}
				<StatGrid>
					<StatCard
						title={organisationRes.data?.data.data.plan?.name || ''}
						value={
							<OrgPlanCount
								organisation={organisationRes.data?.data.data}
							/>
						}
						icon={faChair}
						className="sm:col-span-2"
						link={{
							href: `/${organisation?.slug}/manage`,
							text: 'Manage Organisation',
						}}
						state={{
							permission:
								manageOrganisationActive &&
								organisation?.plan_id !== null,
							isLoading: organisationRes.isLoading,
							isError: organisationRes.isError,
						}}
					/>
					<StatCard
						title={translate('courses_complete')}
						value={coursesCompleted.data?.data.meta?.total || 0}
						icon={faClipboardCheck}
						link={{
							href: navigateLocation('/progress', {
								key: 'completed',
								value: '1',
							}),
							text: 'View',
						}}
						state={{
							isLoading: coursesCompleted.isLoading,
							isError: coursesCompleted.isError,
						}}
					/>
					<StatCard
						title={translate('courses_in_progress')}
						value={coursesInProgress.data?.data.meta?.total || 0}
						icon={faChartSimple}
						link={{
							href: navigateLocation('/progress', {
								key: 'completed',
								value: '0',
							}),
							text: 'View',
						}}
						state={{
							isLoading: coursesInProgress.isLoading,
							isError: coursesInProgress.isError,
						}}
					/>
				</StatGrid>

				{/* User Stats */}
				<TablePreviews
					title={'User Stats'}
					permission={manageOrganisationActive}>
					{/* Active */}
					<TableCard
						title={'Course Progress Today'}
						rows={usersActiveToday.data?.data.data.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('user'),
								type: 'standard',
								hide: !manageOrganisationWithPlan,
							},
							{
								label: translate('organisation'),
								type: 'standard',
								hide: true,
							},
							{
								label: translate('course'),
								type: 'standard',
							},
							{
								label: translate('progress'),
								type: 'standard',
								style: 'text-center justify-center',
							},
							{
								label: translate('started'),
								type: 'standard',
							},
							{
								label: translate('last_activity'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: usersActiveToday.isLoading,
							isError: usersActiveToday.isError,
						}}>
						{usersActiveToday.data?.data.data.map(
							(progress, index) => (
								<ProgressRow
									key={index}
									mode={'organisation'}
									progress={progress}
								/>
							)
						)}
					</TableCard>
					{/* Login Activity */}
					<TableCard
						title={'Login Activity'}
						rows={loginRows?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('user'),
								type: 'standard',
								hide: !manageOrganisationWithPlan,
							},
							{
								label: translate('ip'),
								type: 'standard',
							},
							{
								label: translate('device'),
								type: 'standard',
							},
							{
								label: translate('date'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: logs.isLoading,
							isError: logs.isError,
						}}>
						{loginRows?.map((log, index) => (
							<LoginLogRow key={index} log={log} />
						))}
					</TableCard>
				</TablePreviews>

				{/* Courses */}
				<TablePreviews
					title={'Courses'}
					permission={manageOrganisationActive}>
					{/* Most Taken */}
					<TableCard
						title={'Most taken'}
						rows={mostTakenCourses.data?.data.data?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('title'),
								type: 'standard',
							},
							{
								label: translate('type'),
								type: 'standard',
							},
							{
								label: translate('most_taken'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: mostTakenCourses.isLoading,
							isError: mostTakenCourses.isError,
						}}>
						{mostTakenCourses.data?.data.data.map(
							(course, index) => (
								<OrgCourseRow key={index} course={course} />
							)
						)}
					</TableCard>
				</TablePreviews>

				{/* Video Stats */}
				<TablePreviews
					title={translate('video_views')}
					permission={manageOrganisationActive}>
					<TableCard
						title={'Most Viewed'}
						rows={videoViewCount.data?.data.data?.length || 0}
						rowLimit={5}
						head={[
							{
								label: translate('title'),
								type: 'standard',
							},
							{
								label: translate('views'),
								type: 'standard',
							},
						]}
						state={{
							isLoading: videoViewCount.isLoading,
							isError: videoViewCount.isError,
						}}>
						{videoViewCount.data?.data.data.map((video, index) => (
							<OrgVideoViewRow key={index} video={video} />
						))}
					</TableCard>
				</TablePreviews>

				{/* Last Views */}
				<LastViewedList
					views={videoViews.data?.data.data || []}
					state={{
						isLoading: videoViews.isLoading,
						isError: videoViews.isError,
					}}
					permission={viewCoursesActive}
				/>

				{/* Nominated Courses */}
				<NominatedCoursesList
					permission={viewCoursesActive && hasOrgPlan}
					categories={categories.data?.data.data || []}
					courseProgress={courseProgress.data?.data.data || []}
					state={{
						isLoading: courseDataIsLoading,
						isError: courseDataIsError,
					}}
				/>

				{/* Courses */}
				<CoursesList
					permission={viewCoursesActive}
					categories={categories.data?.data.data || []}
					courseProgress={courseProgress.data?.data.data || []}
					state={{
						isLoading: courseDataIsLoading,
						isError: courseDataIsError,
					}}
				/>

				{/* Certificates */}
				<CertificatesList permission={viewCoursesActive} user={user} />

				{/* Bookmarks */}
				<BookmarksList
					permission={viewCoursesActive && viewCourses}
					categories={categories.data?.data.data || []}
				/>

				{/* Search Again */}
				<SearchAgainList permission={viewCoursesActive} />
			</PageWrapper>
		</>
	);
};

export default StandardView;
